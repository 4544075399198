module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-458c37a02e/0/cache/gatsby-plugin-google-tagmanager-npm-5.8.0-711da3cb42-8b6945bd9a.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WXNMZVN","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-c973489abf/0/cache/gatsby-plugin-manifest-npm-5.8.0-26e7b141df-30f4ec20a4.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f65c4dbb3e9d90292c1e1b01018464f9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-eee9b89250/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
